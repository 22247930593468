import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import { ReactComponent as IconYoutube } from './assets/icons/youtube.svg';
import ImgHeaderLogo from './assets/icons/header-logo.jpg';
import ImgSideLogo from './assets/icons/side-logo.jpg';
import ImgTransparentLogo from './assets/icons/transparent_logo.png';
import ImgGoPLogo from './assets/icons/gop_logo.jpeg';


import "./Extra.css";
import "./App.css";

class App extends React.Component {
  render = () => {

    const openInNewTab = url => {
      window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
      // <div className="card">
      //   {/* <div className="header">
      //     <div className="logo">
      //       <a href=".">
      //         <img src={ImgTransparentLogo} alt="Grip On Pip - Forex" width={260} />
      //       </a>
      //     </div>
      //     <div className="social">
      //       <a href="https://www.facebook.com/griponpip/" title="Facebook" target="_blank" rel="noopener noreferrer">
      //         <IconFacebook className="icon" />
      //       </a>
      //       <a href="https://www.twitter.com/griponpip/" title="Twitter" target="_blank" rel="noopener noreferrer">
      //         <IconTwitter className="icon" />
      //       </a>
      //       <a href="https://www.youtube.com/@GripOnPip/playlists?sub_confirmation=1" title="Youtube" target="_blank" rel="noopener noreferrer">
      //         <IconYoutube className="icon" />
      //       </a>
      //     </div>
      //   </div>
      //   <div className="content">
      //     <div className="content-left">

      //       <div className="image-holder">
      //         <img src={ImgGoPLogo} alt="GoP" width={260} />
      //       </div>

      //       <div className="title-holder">
      //         <h1>Coming Soon</h1>
      //       </div>
      //     </div>
      //     <div className="content-right">
      //       <div className="title-holder">
      //         <h1>Get ready for the change.</h1>
      //         <p>Website coming soon. Please check back to know more. Shoot us an email if you're curious.</p>
      //       </div>
      //       <a href="mailto:griponpip@gmail.com">
      //         <div className="cta">Send us an email</div>
      //       </a>
      //     </div>
      //   </div>
      //   <div className="footer">
      //     <small>
      //       Copyright 2023 by Grip On Pip | All Rights & Licensed Reserved
      //     </small>
      //   </div> */}


      // </div>
      <div>
        <div className="header">
          <div className="logo">
            <div className="logo-text"> 
              Grip On Pip
            </div>
            {/* <a href=".">
              <img src={ImgTransparentLogo} alt="Grip On Pip - Forex" width={220} />
            </a> */}
          </div>
          <div className="social">
            <a href="https://www.facebook.com/griponpip/" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a>
            <a href="https://www.twitter.com/griponpip/" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTwitter className="icon" />
            </a>
            <a href="https://www.youtube.com/@GripOnPip/playlists?sub_confirmation=1" title="Youtube" target="_blank" rel="noopener noreferrer">
              <IconYoutube className="icon" />
            </a>
          </div>
        </div>

        <div className="tab-panel_list">

          <div className="home react-tabs__tab-panel--selected" role="tabpanel" id="panel:R2jm:0" aria-labelledby="tab:R2jm:0">
            <div className="container-fluid main-container container-home p-0 g-0 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
              <div className="color-block d-none d-lg-block"></div>
              <div className="row home-details-container align-items-center">
                <div className="col-lg-4 hero bg position-fixed d-none d-lg-block"></div>
                <div className="col-12 col-lg-8 offset-lg-4 home-details text-center text-lg-start">
                  <div className='content-container'>
                    <img alt="hero man" src={ImgGoPLogo} width="300" height="300" decoding="async" data-nimg="1" className="img-fluid main-img-mobile d-sm-block d-lg-none" loading="lazy" />
                    <h1 className="text-uppercase poppins-font">Welcome to
                      <span className='font-size--25'>Grip on Pip - Forex</span>
                    </h1>
                    <p className="open-sans-font">Welcome to our price action trading education community! This platform will be your one-stop shop for all the things Forex Trader needs to be successful &amp; thrive in their daily forex trades. </p>
                    <p className="open-sans-font"> Here we will guide you to have a strong grip on single trading pair <b>"GBP/USD"</b>.</p>
                    <p className="open-sans-font"> Soon you will be able to achieve <i className='italic-font'>"Pound Trading Plan"</i> targets by following our <b>"Live Signals Account"</b> through self/copy system. All this will be avaialbe soon on this platform for you! </p>
                    <p className="open-sans-font">Stay updated on new exciting features <b>coming soon</b> on this platform. <b>Follow us on YouTube for more updates!</b></p>

                    <button className="button"
                      type="button"
                      onClick={() => openInNewTab('https://www.youtube.com/@GripOnPip/playlists?sub_confirmation=1')}>
                      <span className="button-text">Subscribe Now</span>
                      <span className="button-icon"><IconYoutube className="icon inner-icon" /></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <small>
            Copyright 2023 by Grip On Pip | All Rights & License Reserved
          </small>
        </div>
      </div>


    );
  }
}

export default App;